import React from "react";
import {
  Badge,
  Box,
  Grid,
  Avatar,
  Typography,
  styled,
} from "@mui/material";
import { useSelector } from "react-redux";
import models from "store/models";
import { selectLoggedInUser } from "store/slices/authSlice";
import { useNavigate } from "react-router-dom";

const SidebarFooterContainer = styled(Box)`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const SidebarFooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  cursor: pointer;
`;

const SidebarFooterSubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 0.7rem;
  display: block;
  padding: 1px;
`;

const SidebarFooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) =>
    props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
  cursor: pointer;
`;

interface Props {
    open: boolean;
}

const SidebarFooter: React.FC<Props> = ({ open }) => {
  const loggedInUser = useSelector(selectLoggedInUser);
  const navigate = useNavigate();
  return (
    <SidebarFooterContainer>
      <Grid container spacing={2}>
        <Grid item onClick={() => navigate("/system/profile")}>
          <SidebarFooterBadge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="dot"
          >
            <Avatar>{models.user.initials(loggedInUser)}</Avatar>
          </SidebarFooterBadge>
        </Grid>
        {open && (
          <Grid item>
            <SidebarFooterText variant="body2">
              {`${loggedInUser?.firstName} ${loggedInUser?.lastName}`}
            </SidebarFooterText>
            <SidebarFooterSubText variant="caption">
              {loggedInUser?.email}
            </SidebarFooterSubText>
          </Grid>
        )}
      </Grid>
    </SidebarFooterContainer>
  );
};

export default SidebarFooter