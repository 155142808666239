import { createMongoId } from "utils/createMongoId";
import { ILabel } from "./label.model";

export function initLabel(
  label: Partial<ILabel> & { area: ILabel["area"] }
): ILabel {
  return {
    _id: createMongoId(),
    text: "",
    width: 0,
    x: 0,
    y: 0,
    ...label,
  };
}
