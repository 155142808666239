import React from "react";
import { Box, CssBaseline, GlobalStyles, styled, useTheme } from "@mui/material";


const Root = styled(Box)`
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100vh;
`;

const Auth: React.FC<{children: React.ReactNode}> = ({ children }) => {
  const theme = useTheme();
  return (
    <Root>
      <CssBaseline />
      <GlobalStyles
        styles={{
          html: { height: "100%" },
          body: {
            height: "100%",
            background: theme.palette.background.default,
          },
          root: { height: "100%" },
        }}
      />
      {children}
    </Root>
  );
};

export default Auth;
