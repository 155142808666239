import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Button, Typography, styled, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { selectLoginFailMessage } from "store/slices/authSlice";


const Wrapper = styled(Box)`
  padding: ${(props) => props.theme.spacing(6)};
  text-align: center;
  background: transparent;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function Page404() {

  const loginFailMessage = useSelector(selectLoginFailMessage)

  return (
    <Wrapper>
      <Helmet title="404 Error" />
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        404
      </Typography>
      <Typography component="h2" variant="h5" align="center" gutterBottom>
        {loginFailMessage?.title || "Page not found."}
      </Typography>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        {loginFailMessage?.subtitle || "The page you are looking for might have been removed."}
      </Typography>

      <Button
        component={Link}
        to="/"
        variant="contained"
        color="secondary"
        sx={{marginTop: 2}}
      >
        Return to website
      </Button>
    </Wrapper>
  );
}

export default Page404;
