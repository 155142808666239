import { oneMinute } from "constants/intervals";
import { getResponseBody } from "store/api/utils/apiUtilv2";
import { IInstance } from "store/models/instance.model";
import { IInstancePermission } from "store/models/instancePermission.model";
import { IUser } from "store/models/user.model";
import { atlasApi } from "store/services/api";

export const instanceApi = atlasApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getUserInstances: builder.query<IInstance[], void>({
        query: (query) => `instance`,
        transformResponse: getResponseBody,
        providesTags: ["Instances"],
        keepUnusedDataFor: oneMinute * 60 * 8,
        extraOptions: {maxRetries: 0}
    }),
    getUserInstancePermission: builder.query<IInstancePermission, {user: IUser, instanceName: IInstance["name"]}>({
      query: (query) => `instance/permission`,
      transformResponse: getResponseBody,
      keepUnusedDataFor: oneMinute * 60 * 8
  }),
  })
});

export const { useGetUserInstancesQuery, useLazyGetUserInstancePermissionQuery } = instanceApi;


