  export const isObjectId = (id: string) => {
    return id.length === 24 && id.match(/^[0-9a-fA-F]{24}$/);
  };

  export function isOfDocumentType<T extends { _id: string }>(document: any): document is T{
    return (document as T)?._id !== undefined;
  }

  export function getFullName(user: {firstName: string, lastName: string}) {
    return `${user.firstName} ${user.lastName}`;
  }
  
  