import { IProject } from "store/models/project.model";
import { IStation } from "store/models/station.model";
import { AtlasStatus } from "types/AtlasStatus";
import { IUser } from "./user.model";

export enum IssueType {
  Project = "Project",
  Station = "Station",
}

export interface IIssue<ParentType = string> {
  _id: string;
  description: string;
  createdDate: string;
  createdBy: IUser["_id"];
  station: string | null;
  parent: ParentType;
  issueType: IssueType;
  issueNumber: number;
  severity: AtlasStatus.YELLOW | AtlasStatus.RED;
  category: string;
  subCategory: string;
  resolution: string;
  resolvedBy: IUser["_id"] | null;
  resolvedDate: string | null;
  timeLost: number | null;
}



export interface IPopulatedIssue
  extends Omit<IIssue, "createdBy" | "station" | "parent" | "resolvedBy"> {
  createdBy: IUser;
  station: IStation;
  parent: IStation | IProject;
  resolvedBy: IUser | null
}
