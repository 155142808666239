import React, { useState } from "react";
import { RouteType } from "types/routes";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Box, List, useTheme } from "@mui/material";
import SidebarCategory from "components/sidebar/SidebarCategory";

export type tplotOptions = {
  [key: number]: boolean;
};

interface SidebarNavigationProps {
  routes: Array<RouteType>;
  open: boolean;
  onClose: () => void;
}

const SidebarNavigation: React.FC<SidebarNavigationProps> = ({
  routes,
  open,
  onClose,
}) => {
  const initOpenRoutes = (): tplotOptions => {
    /* Open collapse element that matches current url */
    const pathName = location.pathname;

    let _routes = {};

    routes.forEach((route: RouteType, index) => {
      const isActive = pathName.indexOf(route.path) === 0;
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === "/";

      _routes = Object.assign({}, _routes, {
        [index]: isActive || isOpen || isHome,
      });
    });

    return _routes;
  };

  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes());

  const toggle = (index: number) => {
    collapseOpenRoute(index);
    // Toggle selected element
    setOpenRoutes((openRoutes) =>
      Object.assign({}, openRoutes, { [index]: !openRoutes[index] })
    );
  };

  function collapseOpenRoute(index: number) {
    Object.keys(openRoutes).forEach(
      (item) =>
        openRoutes[index] ||
        setOpenRoutes((openRoutes) =>
          Object.assign({}, openRoutes, { [item]: false })
        )
    );
  }
  const theme = useTheme();
  return (
    <PerfectScrollbar style={{ backgroundColor: theme.sidebar.background }}>
      <List sx={{ backgroundColor: theme.sidebar.background }} disablePadding>
        <Box sx={{ paddingVertical: 2.5 }}>
          {routes.map((category: RouteType, index) => (
            <SidebarCategory
              key={index}
              open={open}
              index={index}
              openRoutes={openRoutes}
              toggle={toggle}
              category={category}
              onClose={onClose}
            />
          ))}
        </Box>
      </List>
    </PerfectScrollbar>
  );
};

export default SidebarNavigation;
