import models from ".";
import { IZip, ZIP_STATUS, ZIP_TYPES } from "./zip.model";
import { isEqual, subMinutes, isBefore, add } from "date-fns";
import { initMessage } from "./message";
import { ZipQueryResponse } from "store/services/zippy";
import { isOfDocumentType } from "utils/util";
import { IStation } from "store/models/station.model";
import { IProject } from "store/models/project.model";
import { createMongoId } from "utils/createMongoId";

export function initZip(
  zip: Partial<IZip> & {
    originator: IZip["originator"];
    deliveryLocation: IZip["deliveryLocation"];
  }
): IZip {

  return {
    _id: createMongoId(),
    type: ZIP_TYPES.materialRequest,
    metaTags: [],
    assignedTo: null,
    deliveryLocationType: "Station",
    originatingStation: "",
    dateCreated: new Date().toISOString(),
    dateRequired: String(createDateRequired()),
    dateMarkedComplete: undefined,
    requiredEquipment: [],
    orderItem: models.orderItem.initOrderItem(),
    status: ZIP_STATUS.toDo,
    statusChangeLog: [],
    zipNumber: 1,
    zipChat: [initMessage({ user: zip.originator })],
    onHoldReason: "",
    onHoldUntil: "",
    planned: false,
    ...zip,
  };
}



function createDateRequired() {
  const currentDateTime = new Date();
  //adds 2 hours and rounds up the next cloestest 5 min increment
  return add(currentDateTime,{
    hours: 2,
    minutes: Math.abs((currentDateTime.getMinutes() % 5) - 5),
  });
}

export const isEarly = (zip: ZipQueryResponse | IZip) => {
  return zip.dateMarkedComplete
    ? new Date(zip.dateMarkedComplete) <
        subMinutes(new Date(zip.dateRequired), 15)
    : false;
};

export const isOnTime = (zip: ZipQueryResponse | IZip) => {
  return zip.dateMarkedComplete
    ? !isDeliveredLate(zip) && !isEarly(zip)
    : false;
};

export const isDeliveredLate = (zip: ZipQueryResponse | IZip) => {
  return zip.dateMarkedComplete
    ? new Date(zip.dateMarkedComplete) > new Date(zip.dateRequired)
    : false;
};

export const isLate = (date: string) => new Date() > new Date(date);

export const isAssigned = (zip: IZip) => zip.assignedTo;
export const isUnassigned = (zip: IZip) => !isAssigned(zip);

export const getDescription = (zip: ZipQueryResponse) => {

  switch (true) {
    case Boolean(zip.orderItem.material):
      return zip.orderItem.material?.description;
    case zip.type === ZIP_TYPES.garbagePickup:
      return zip.type;
    default:
      return zip.orderItem.itemDescription;
  }
};

export const getDeliveryLocationName = (zip: ZipQueryResponse) => {
  if (
    isOfDocumentType<IStation>(zip.deliveryLocation) &&
    zip.deliveryLocation.longName
  ) {
    return zip.deliveryLocation.longName;
  } else if (
    isOfDocumentType<IProject>(zip.deliveryLocation) &&
    zip.deliveryLocation.name
  ) {
    return zip.deliveryLocation.name;
  }
};

export const isComplete = (zip: ZipQueryResponse) =>
  zip?.status === ZIP_STATUS.complete && zip?.dateMarkedComplete;

export function sortZipsByDueDate(first: {dateRequired: string}, second: {dateRequired: string}) {
  const firstDate = new Date(first.dateRequired);
  const secondDate = new Date(second.dateRequired);

  if (isBefore(firstDate,secondDate) || isEqual(firstDate,secondDate)) return -1;
  return 1;
}
