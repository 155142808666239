import { AtlasStatus } from "types/AtlasStatus";
import { createMongoId } from "utils/createMongoId";
import { minutesToHours } from "utils/formatUtils";
import { IIssue } from "./issue.model";
import { ITaktSheet } from "./taktSheet.model";


export function activeTasks(taktsheet: ITaktSheet){
  return taktsheet.tasks.filter(task => !task.removedBy)
}

export function plannedHours(taktSheet: ITaktSheet) {
   const hours = taktSheet?.tasks.reduce((plannedHours, task) => {
    if (!task.removedBy) {
      return plannedHours + minutesToHours(task.timeRequired || 0);
    } else {
      return plannedHours;
    }
  }, 0);

  return parseFloat(hours.toFixed(2))
}

export function initTaktSheet (station?: ITaktSheet["station"]): ITaktSheet{
  return {
    _id: createMongoId(),
    tasks: [],
    requiredMaterials: [],
    status: AtlasStatus.RED,
    touched: false,
    station: station || "",
    completedBy: null,
    completedDate: null
  }
}

export type TaktSheetIssueMap = { [category: string]: { [subCategory: string] : { [id: string]: IIssue } }}