import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppStateType } from "store/store";
import { IIssue, IPopulatedIssue } from "store/models/issue.model";
import { addDays, startOfToday } from "date-fns";



const initialState: {
  issueCategoryFilter: IIssue["category"];
  issueNumberFilter: IIssue["issueNumber"]
} = {
  issueCategoryFilter: "",
  issueNumberFilter: 0
};

const { actions, reducer } = createSlice({
  name: "issues",
  initialState,
  reducers: {
    setIssueCategoryFilter(
      state,
      action: PayloadAction<typeof initialState["issueCategoryFilter"]>
    ) {
      state.issueCategoryFilter = action.payload;
    },
    setIssueNumberFilter(
      state,
      action: PayloadAction<number>
    ) {
      state.issueNumberFilter = action.payload;
    },
  },

});


export function issueIsActive(issue: IIssue | IPopulatedIssue) {
  return !issue.resolution;
}


export const issueCreatedToday = (issue: IIssue | IPopulatedIssue) =>
  new Date(issue.createdDate) > startOfToday();

export const issuesCreatedYesterday = (issue: IIssue | IPopulatedIssue) =>
  new Date(issue.createdDate) > addDays(startOfToday(), -1);

export const selectIssueCategoryFilter = (state: AppStateType) =>
  state.issues.issueCategoryFilter;

export const selectIssueNumberFilter = (state: AppStateType) =>
  state.issues.issueNumberFilter;

export const {
  setIssueCategoryFilter,
  setIssueNumberFilter
} = actions;
export default reducer;
