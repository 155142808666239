import React, { useState } from "react";
import {
  Box,
  Button as MuiButton,
  Typography,
  styled,
} from "@mui/material";
import { ReactComponent as Logo } from "vendor/logo.svg";
import { ReactComponent as OktoLogo } from "vendor/oktaLogo.svg";
import { useAuthContext } from "auth/use-auth-context";
import Auth from "layouts/Auth";

const AppTitle = styled(Typography)`
  margin: 0;
`;

const SignInButton = styled(MuiButton)`
  grid-column-start: 1;
  grid-column-end: 4;
`;

const OktaLogo = styled(OktoLogo)`
  margin: 0 auto;
  height: 15px;
  margin-bottom: 2px;
  grid-column-start: 1;
  grid-column-end: 3;
  fill: ${(props) => props.theme.palette.primary.contrastText};
`;

const ErrorWarningText = styled(Typography)`
  margin: 0 auto;
  grid-column-start: 1;
  grid-column-end: 3;
  max-width: 240px;
  text-align: center;
`;

const ATLASIcon = styled(Logo)`
  margin-right: ${(props) => props.theme.spacing(2)};
  fill: ${(props) => props.theme.palette.primary.main};
  margin: 0 auto;
  width: 60px;
  height: 40px;
  display: block;
`;

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
`;

function SignIn() {

  const [showErrorText, changeShowErrorText] = useState(false);

  const { loginWithRedirect } = useAuthContext();

  return (
    <Auth>
      <Container>
        <Box sx={{display: "flex", my: 3}}>
        <ATLASIcon />
        <AppTitle variant="h1" align="center" gutterBottom>
          Atlas Global
        </AppTitle>
        </Box>
        <SignInButton
          variant="contained"
          color="primary"
          onClick={loginWithRedirect}
          endIcon={<OktaLogo />}
          size="large"
        >
          Sign in with
        </SignInButton>
        {showErrorText && (
          <ErrorWarningText color="error">
            If you are having trouble logging in please enable cookies in your
            browser
          </ErrorWarningText>
        )}
      </Container>
    </Auth>
  );
}

export default SignIn;
