import React, { ElementType } from "react";
import { NavLink, LinkProps } from "react-router-dom";
import { darken } from "polished";
import { Box, ListItem, styled } from "@mui/material";
import MiniIcon from "@mui/icons-material/ChevronRight";
import { ReactComponent as Logo } from "vendor/logo.svg";

const Brand = styled(ListItem)<{
    button?: boolean;
    component?: ElementType;
    to?: string;
  }>`
    font-size: ${(props) => props.theme.typography.h5.fontSize};
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    color: ${(props) => props.theme.sidebar.header.color};
    background-color: ${(props) => props.theme.sidebar.background};
    font-family: ${(props) => props.theme.typography.fontFamily};
    min-height: 56px;
    list-style-type: none;
    padding: ${(props) => props.theme.spacing(6)};
    cursor: pointer;
  
    ${(props) => props.theme.breakpoints.up("sm")} {
      min-height: 64px;
    }
  
    &:hover {
      background-color: ${(props) =>
        darken(0.03, props.theme.sidebar.background)};
    }
  `;
  
  const BrandIcon = styled(Logo)`
    color: ${(props) => props.theme.sidebar.header.brand.color};
    fill: ${(props) => props.theme.sidebar.header.brand.color};
    width: 32px;
    height: 32px;
  `;
  
  type BrandLinkPropsType = {
    open: boolean;
    onClose?: () => void;
  };
  
  const SidebarBrand: React.FC<BrandLinkPropsType> = ({ open, onClose }) => {
    const CustomLink = React.useMemo(
      () =>
        React.forwardRef<HTMLAnchorElement, Omit<LinkProps, "to">>(
          function SomeLink(linkProps, ref) {
            return <NavLink ref={ref} to={"/"} {...linkProps} />;
          }
        ),
      []
    );
    return (
      <React.Fragment>
        {open ? (
          <Brand component={CustomLink}>
            <BrandIcon sx={{ mr: 2 }} />
            <Box ml={1}>ATLAS</Box>
          </Brand>
        ) : (
          <Brand onClick={onClose} aria-label="Open drawer">
            <BrandIcon />
            <MiniIcon sx={{position: "fixed", left: "80px"}} />
          </Brand>
        )}
      </React.Fragment>
    );
  };

  export default SidebarBrand