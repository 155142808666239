import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Button, Typography, styled, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { selectLoginFailMessage } from "store/slices/authSlice";
import { useAuthContext } from "auth/use-auth-context";

const Wrapper = styled(Box)`
  padding: ${(props) => props.theme.spacing(6)};
  text-align: center;
  background: transparent;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function Page403() {
  const loginFailMessage = useSelector(selectLoginFailMessage);
  const { logout } = useAuthContext();

  return (
    <Wrapper>
      <Helmet title="403 Error" />
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        403
      </Typography>
      <Typography component="h2" variant="h5" align="center" gutterBottom>
        {loginFailMessage?.title || "Unauthorized."}
      </Typography>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        {loginFailMessage?.subtitle ||
          "You are not authorized to view this content"}
      </Typography>
      {loginFailMessage ? (
        <Button
          onClick={logout}
          variant="contained"
          color="secondary"
          sx={{ marginTop: 2 }}
        >
          Logout
        </Button>
      ) : (
        <Button
          component={Link}
          to="/"
          variant="contained"
          color="secondary"
          sx={{ marginTop: 2 }}
        >
          Return to website
        </Button>
      )}
    </Wrapper>
  );
}

export default Page403;
