import { createMongoId } from "utils/createMongoId";
import { IRequiredMaterial } from "./requiredMaterial.model";
import store from "store/store";

export function initRequiredMaterial(
  requiredMaterial?: Partial<IRequiredMaterial>
): IRequiredMaterial {
  return {
    _id: createMongoId(),
    removedBy: undefined,
    quantity: 1,
    material: "",
    wasOnTakeOffAtTimeOfCreation: false,
    zipId: null,
    createdBy: store.getState().auth.loggedInUser._id,
    ...requiredMaterial,
  };
}