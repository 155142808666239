import { createMongoId } from "utils/createMongoId";
import { DateString } from "utils/formatUtils";
import { IProject } from "./project.model";

export function initProject(
  project: Partial<IProject> & { startingArea: IProject["startingArea"] }
) {
  return {
    _id: createMongoId(),
    name: "",
    projectNumber: "",
    unitNumber: "",
    serialNumber: "",
    materialProjectNumber: "",
    hoursBudgeted: 0,
    hoursConsumed: 0,
    projectManager: null,
    description: "",
    plannedInDate: "" as DateString,
    plannedOutDate: "" as DateString,
    productionLine: "",
    plan: null,
    planExecution: null,
    complete: false,
    ...project,
  };
}

export function shortProjectIdentifier(
  project: IProject,
  numberOfDigits: number = 4
) {
  const startingNumber = project.unitNumber.length - numberOfDigits
  return (
    project.unitNumber?.substr(
      Math.max(startingNumber, 0),
      numberOfDigits
    ) || ""
  );
}


export function projectIsExecuted(project: IProject) {
  return project.planExecution;
}

export function projectHasAPlan(project: IProject) {
  return project.plan;
}
