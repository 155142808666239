import { AuthContext } from 'auth/context/azure';
import { useContext } from 'react';


export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) throw new Error('useAuthContext context must be use inside AuthProvider');

  return context;
};
