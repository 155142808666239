import { IUser } from "./user.model";
import { IOrderItem } from "store/models/order-item.model";
import { IStation } from "store/models/station.model";
import { IChatMessage } from "store/models/message.model";

export enum ON_HOLD_REASON {
  outOfStock = "Out Of Stock",
  notOnTakeOff = "Not On Take Off",
  notReady = "Not Ready",
}

export enum ZIP_STATUS {
  onHold = "On Hold",
  toDo = "To Do",
  inProgress = "In Progress",
  complete = "Complete",
}

export enum ZIP_TYPES {
  materialRequest = "Material Request",
  garbagePickup = "Garbage Pickup",
  materialReturn = "Material Return",
}

export interface IStatusChangelog {
  userId: string;
  date: Date;
  oldStatus: string;
  newStatus: string;
}

export type DeliveryLocation = "Station" | "Project";

export interface IZip {
    _id: string;
    type: ZIP_TYPES;
    metaTags: string[];
    assignedTo: IUser["_id"] | null;
    originator: IUser["_id"];
    deliveryLocationType: DeliveryLocation;
    deliveryLocation: IStation["_id"];
    originatingStation?: string | null
    dateCreated: string;
    dateRequired: string;
    dateMarkedComplete: string | undefined;
    requiredEquipment: string[];
    orderItem: IOrderItem;
    status: ZIP_STATUS,
    statusChangeLog: IStatusChangelog[],
    zipNumber: number,
    zipChat: IChatMessage[],
    onHoldReason: string,
    onHoldUntil: string,
    planned: boolean;
}