import React from "react";
import { RouteType } from "types/routes";
import "react-perfect-scrollbar/dist/css/styles.css";
import { Drawer as MuiDrawer, styled } from "@mui/material";
import { useRoutesConfig } from "routes/routesConfig";
import SidebarCategories from "components/sidebar/SidebarNavigation";
import SidebarFooter from "components/sidebar/SidebarFooter";
import SidebarBrand from "components/sidebar/SidebarBrand";

const Drawer = styled(MuiDrawer)`
  border-right: 0;
  > div {
    border-right: 0;
  }
  transition-duration: 0.2s;
`;

type SidebarPropsType = {
  classes?: string;
  routes: Array<RouteType>;
  PaperProps: {
    style: {
      width: number;
    };
  };
  variant?: "permanent" | "persistent" | "temporary";
  open: boolean;
  onClose: () => void;
};

const Sidebar: React.FC<SidebarPropsType> = ({ classes, ...rest }) => {
  const routes = useRoutesConfig().sidebarRoutes;
  const { open } = rest;

  return (
    <Drawer variant="permanent" {...rest}>
      <SidebarBrand open={open} onClose={rest.onClose} />
      <SidebarCategories routes={routes} open={open} onClose={rest.onClose} />
      <SidebarFooter open={open} />
    </Drawer>
  );
};

export default Sidebar;
