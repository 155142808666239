import { createMongoId } from "utils/createMongoId";
import { IOrderItem } from "./order-item.model";

export function initOrderItem(orderItem: Partial<IOrderItem> = {}): IOrderItem {
    return {
        _id: createMongoId(),
        material: null,
        itemDescription: "",
        quantity: 1,
        unitOfMeasure: "",
        ...orderItem
    }
}