import { oneMinute } from "constants/intervals";
import { getResponseBody } from "store/api/utils/apiUtilv2";
import { HTTPMethod } from "store/api/utils/HttpMethodsEnum";
import { atlasApi } from "store/services/api";
import { ConfigType } from "store/slices/configSlice";

export const configApi = atlasApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getConfigs: builder.query<ConfigType[], void>({
        query: () => "config",
        transformResponse: getResponseBody,
        providesTags: ["Configs"],
        keepUnusedDataFor: oneMinute * 60 * 8
    }),
    updateConfig: builder.mutation<ConfigType, Partial<ConfigType>>({
        query: (config) => ({
            url: `config`,
            method: HTTPMethod.PATCH,
            body: config,
        }),
        transformResponse: getResponseBody,
        invalidatesTags: ["Configs"],
    })

  })
});

export const { useGetConfigsQuery, useUpdateConfigMutation } = configApi;
