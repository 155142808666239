import React, { ElementType } from "react";
import { rgba } from "polished";
import { NavLink, LinkProps, useNavigate } from "react-router-dom";
import { darken } from "polished";
import { RouteType, RouteChildType } from "types/routes";
import {
  Chip,
  Collapse,
  ListItem,
  ListItemText,
  Typography,
  Link,
  styled,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { tplotOptions } from "components/sidebar/SidebarNavigation";

type CategoryType = {
  activeclassname?: string;
  button?: boolean;
  onClick?: () => void;
  to?: string;
  component?: typeof NavLink;
  exact?: string;
};

const HeaderContainer = styled(ListItem)<CategoryType>`
  padding-top: ${(props) => props.theme.spacing(3)};
  padding-bottom: ${(props) => props.theme.spacing(3)};
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(5)};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  cursor: pointer;
  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  &.${(props) => props.activeclassname} {
    background-color: ${(props) =>
      darken(0.03, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const HeaderText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) => props.theme.sidebar.color};
    font-size: ${(props) => props.theme.typography.body1.fontSize};
    padding: 0 ${(props) => props.theme.spacing(4)};
  }
`;

const SubheaderLink = styled(ListItem)<{
    activeclassname: string;
    component: ElementType;
    exact?: string;
    to?: string;
    href?: string;
    target?: string;
    rel?: string;
  }>`
    padding-left: ${(props) => props.theme.spacing(15)};
    padding-top: ${(props) => props.theme.spacing(1)};
    padding-bottom: ${(props) => props.theme.spacing(1)};
    span {
      color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
    }
  
    &:hover span {
      color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
    }
  
    &:hover {
      background-color: ${(props) =>
        darken(0.015, props.theme.sidebar.background)};
    }
  
    &.${(props) => props.activeclassname} {
      background-color: ${(props) =>
        darken(0.03, props.theme.sidebar.background)};
  
      span {
        color: ${(props) => props.theme.sidebar.color};
      }
    }
  `;
  


const LinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize};
  }
  margin-top: 0;
  margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 28px;
  top: 8px;
  background: ${(props) => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const SectionHeader = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  padding: ${(props) => props.theme.spacing(4,7,1)};
  opacity: 0.9;
  display: block;
`;

type CategoryHeaderProps = {
  name: string;
  icon: JSX.Element;
  classes?: string;
  isOpen?: boolean;
  open?: boolean;
  isCollapsable: boolean;
  badge?: string | number;
  activeclassname?: string;
  onClick?: () => void;
  to?: string;
  component?: typeof NavLink;
  exact?: string;
};

const CategoryHeader: React.FC<CategoryHeaderProps> = ({
  name,
  icon,
  classes,
  isOpen,
  open,
  isCollapsable,
  badge,
  ...rest
}) => {
  return (
    <HeaderContainer {...rest}>
      {icon}
      {open && <HeaderText>{name}</HeaderText>}
      {isCollapsable ? isOpen ? <ExpandMore /> : <ExpandLess /> : null}
      {badge ? <CategoryBadge label={badge} /> : ""}
    </HeaderContainer>
  );
};

type CategorySubheaderProps = {
  name: string;
  to: string;
  badge?: string | number;
  icon?: JSX.Element;
  primary?: string;
};

const CategorySubheader: React.FC<CategorySubheaderProps> = ({
  name,
  to,
  badge,
  icon,
}) => {
  const navigate = useNavigate();
  const CustomLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement, Omit<LinkProps, "to">>(
        function SomeLink(linkProps, ref) {
          return <NavLink ref={ref} to={to} {...linkProps} />;
        }
      ),
    [to]
  );
  if (pathIsExternal()) {
    return (
      <SubheaderLink
        activeclassname="active"
        component={Link}
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        sx={{ cursor: "pointer" }}
      >
        <LinkText>{name}</LinkText>
        {badge ? <LinkBadge label={badge} /> : ""}
      </SubheaderLink>
    );
  }

  function pathIsExternal() {
    return to.includes("https://");
  }

  return (
    <SubheaderLink dense activeclassname="active" component={CustomLink}>
      <ListItemText primary={name} />
    </SubheaderLink>
  );
};



interface Props {
  category: RouteType;
  open: boolean;
  onClose: () => void;
  openRoutes: tplotOptions;
  index: number;
  toggle: (index: number) => void;
}

const SidebarCategory: React.FC<Props> = ({
  category,
  open,
  openRoutes,
  index,
  toggle,
  onClose,
}) => {
  return (
    <React.Fragment>
      {category.header && <SectionHeader>{category.header}</SectionHeader>}

      {category.children && category.icon ? (
        <React.Fragment>
          <CategoryHeader
            isOpen={!openRoutes[index]}
            isCollapsable={open || false}
            name={open ? category.id : ""}
            icon={category.icon}
            open={open}
            onClick={() => {
              toggle(index);
              if (onClose && !open) onClose();
            }}
          />

          {open && (
            <Collapse in={openRoutes[index]} timeout="auto" unmountOnExit>
              {category.children.map((route: RouteChildType, index: number) => (
                <CategorySubheader
                  key={index}
                  name={open ? route.name : ""}
                  to={route.path}
                  icon={route.icon}
                  badge={open ? route.badge : undefined}
                />
              ))}
            </Collapse>
          )}
        </React.Fragment>
      ) : category.icon ? (
        <CategoryHeader
          isCollapsable={false}
          name={open ? category.id : ""}
          to={category.path}
          activeclassname="active"
          component={NavLink}
          icon={category.icon}
          open={open}
          exact="true"
          badge={open ? category.badge : undefined}
        />
      ) : null}
    </React.Fragment>
  );
};

export default SidebarCategory;
