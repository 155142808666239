import * as React from "react";
import PowerSettingsNew from "@mui/icons-material/PowerSettingsNew";
import {
  Tooltip,
  List,
  ListItem,
  IconButton as MuiIconButton,
  Popover as MuiPopover,
  ListItemText,
  styled,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useAuthContext } from "auth/use-auth-context";
import { useDispatch, useSelector } from "react-redux";
import { selectAtlasInstance, setAtlasInstance } from "store/slices/authSlice";
import { atlasApi } from "store/services/api";
import { useGetUserInstancesQuery } from "store/services/instances";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function UserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);
  const { logout } = useAuthContext();
  const dispatch = useDispatch();
  const atlasInstance = useSelector(selectAtlasInstance);
  const {
    data: userInstances = [],
    isLoading,
    error,
  } = useGetUserInstancesQuery(undefined, { refetchOnMountOrArgChange: true });

  const openMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeList = () => {
    setAnchorMenu(null);
  };

  return (
    <React.Fragment>
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "account-menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={openMenu}
          color="inherit"
        >
          <PowerSettingsNew />
        </IconButton>
      </Tooltip>
      <Popover
        id="account-menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeList}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <List disablePadding>
          <ListItem sx={{mt: 3}}>
            <FormControl>
              <InputLabel id="select-personnel-type">Instance</InputLabel>
              <Select
                value={atlasInstance || ""}
                onChange={(e) => {
                  dispatch(atlasApi.util.resetApiState())
                  dispatch(setAtlasInstance(e.target.value))
                }}
                size="small"
                label="Instance"
              >
                {userInstances.map((instance) => (
                  <MenuItem value={`${instance.name}`} key={instance.name}>
                    {instance.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </ListItem>
          <ListItem button onClick={logout}>
            <ListItemText sx={{ml: 0.5}} primary="Sign out" />
          </ListItem>
        </List>
      </Popover>
    </React.Fragment>
  );
}

export default UserDropdown;
