import { IBestKnownMethod } from "store/models/bestKnownMethod.model";
import { createMongoId } from "utils/createMongoId";

export function initBestKnownMethod(
  bkmProps: Partial<IBestKnownMethod> = {}
): IBestKnownMethod {
  return {
    _id: createMongoId(),
    title: "",
    description: "",
    metaTags: [],
    links: [],
    notes: "",
    revisionHistory: [],
    ...bkmProps,
  };
}
