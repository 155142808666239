import {
  IQualification,
  IQualificationRecord,
  ITrainingTableRow,
} from "store/models/qualification.model";
import { createMongoId } from "utils/createMongoId";

export function initQualification(
  qualification: Partial<IQualification> = {}
): IQualification {
  return {
    _id: createMongoId(),
    description: "",
    type: "Training",
    issuer: "",
    daysValid: 365,
    ...qualification,
  };
}

export const initTrainingTableRow = (): ITrainingTableRow => {
  return { employee: "", qualification: "", index: 0 };
};

export function initQualificationRecord(
  qualificationRecord: Partial<IQualificationRecord> & { qualification: string }
): IQualificationRecord {
  return {
    _id: createMongoId(),
    createdOn: new Date().toISOString(),
    rating: "",
    flagged: false,
    lastRating: "",
    lastUpdated: new Date().toISOString(),
    ...qualificationRecord,
  };
}
