import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import rootReducer from "store/slices/rootReducer";
import { atlasApi } from "./services/api";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(atlasApi.middleware),
  devTools: process.env.REACT_APP_ATLAS_ENV !== "production",
});

//Hot Module Reloading reducers whenever a reducer code changes
if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./slices/rootReducer", () => {
    const newRootReducer = require("./slices/rootReducer").default;
    store.replaceReducer(newRootReducer);
  });
}

// Infer the `RootState` and `AppDispatch` types
export type AppStateType = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
