import React from "react";
import {
  Grid,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Typography,
  capitalize,
  styled
} from "@mui/material";
import ExpandIcon from "@mui/icons-material/ChevronLeft";
import UserDropdown from "components/header/UserDropdown";
import ToggleThemeButton from "components/header/ToggleThemeButton";
import SiteFilterDropdown from "components/selects/SiteFilterSelect";
import { isStaging } from "theme/variants";
import { useLocation } from "react-router-dom";

const AppBar = styled(MuiAppBar)<{ clip: string }>(({theme, clip}) => ({
  background: isStaging() ? theme.header.background : theme.palette.background.default,
  color: theme.header.color,
  transition: "0.2s"
}))



const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

type AppBarProps = {
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
  open: boolean;
};

const AppBarComponent: React.FC<AppBarProps> = ({ onDrawerToggle, open }) => {

  const location = useLocation()
  function rightContent() {
    switch (true) {
      case location.pathname.includes("site"):
        return <SiteFilterDropdown />;
    }
  }

  function centerContent() {
    switch (true) {
      case isStaging():
        return (
          <Typography variant="h3">
            Atlas {capitalize(process.env.REACT_APP_ATLAS_ENV || "")}
          </Typography>
        );
    
    }
  }

  return (
    <React.Fragment>
      <AppBar clip={String(open) || ""} position={"sticky"} elevation={0} color="secondary">
        <Toolbar>
          <Grid container alignItems="center">
            {open && (
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
              >
                <ExpandIcon />
              </IconButton>
            )}
            <Grid item xs />
            {centerContent()}
            <Grid item xs />
            <Grid item>
              {rightContent()}
              <ToggleThemeButton />
              <UserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default AppBarComponent;
