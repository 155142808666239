import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppStateType } from "store/store";
import { IStation } from "store/models/station.model";
import { IIssue } from "store/models/issue.model";
import { ShopMapIssue } from "store/services/types";


const initialState: {
  showRollCalledStations: boolean;
  showConsumedHours: boolean;
  showContractorStations: boolean;
  showPlannedHours: boolean;
  showEndOfSerialNumber: boolean;
  filterTaktTime?: number;
} = {
  showRollCalledStations: true,
  showConsumedHours: false,
  showContractorStations: false,
  showPlannedHours: false,
  showEndOfSerialNumber: false,
  filterTaktTime: undefined
};

const { actions, reducer } = createSlice({
  name: "stations",
  initialState,
  reducers: {
    setShowRollCalledStations(state, action: PayloadAction<boolean>) {
      state.showRollCalledStations = action.payload;
    },
    setShowConsumedHours(state, action: PayloadAction<boolean>) {
      state.showConsumedHours = action.payload;
    },
    setShowContractorStations(state, action: PayloadAction<boolean>) {
      state.showContractorStations = action.payload;
    },
    setShowPlannedHours(state, action: PayloadAction<boolean>) {
      state.showPlannedHours = action.payload;
    },
    setShowEndOfSerialNumber(state, action: PayloadAction<boolean>) {
      state.showEndOfSerialNumber = action.payload;
    },
    setFilterTaktTime(state, action: PayloadAction<number>) {
      if (isNaN(action.payload) || !action.payload) {
        state.filterTaktTime = undefined;
      } else {
        state.filterTaktTime = Number(action.payload);
      }
    },
  },
});

export const selectShowPlannedHours = (state: AppStateType) =>
  state.stations.showPlannedHours;
export const selectShowEndOfSerialNumber = (state: AppStateType) =>
  state.stations.showEndOfSerialNumber;
export const selectFilterTaktTime = (state: AppStateType) =>
  state.stations.filterTaktTime;
export const selectShowRollCalledStations = (state: AppStateType) =>
  state.stations.showRollCalledStations;
export const selectShowConsumedHours = (state: AppStateType) =>
  state.stations.showConsumedHours;
export const selectShowContractorStations = (state: AppStateType) =>
  state.stations.showContractorStations;

export function issueShouldShowInStation(issue: IIssue | ShopMapIssue, station: IStation) {
  return (issue.station && station.projectIssueFilter.includes(issue.station)) || !station.projectIssueFilter.length
}


export const {
  setShowRollCalledStations,
  setShowConsumedHours,
  setShowContractorStations,
  setShowPlannedHours,
  setShowEndOfSerialNumber,
  setFilterTaktTime,
} = actions;

export default reducer;
