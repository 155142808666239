import { IProject } from "./project.model";
import { IStation } from "./station.model";
import { createMongoId } from "utils/createMongoId";

export function initStation(station: Partial<IStation>): IStation {
  return {
    _id: createMongoId(),
    longName: "",
    shortName: "",
    area: "",
    assignedTo: null,
    projects: [],
    stakeholders: [],
    x: 0,
    y: 0,
    degreesRotation: 0,
    metaTags: [],
    lastRollCall: new Date().toISOString(),
    projectIssueFilter: [],
    ...station,
  };
}


export function projectAlreadyInThisStation(
  station: IStation,
  projectIds: IProject["_id"][]
) {
  return station.projects.some((projectId) => projectIds.includes(projectId));
}
