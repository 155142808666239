import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import { createRoot } from "react-dom/client";
import App from "app/App";
import { EventType, AuthenticationResult } from "@azure/msal-browser";
import { loginRequest } from "auth/authConfig";
import { msalInstance } from "auth/context/azure/msalInstance";
import { BrowserRouter } from "react-router-dom";


msalInstance.initialize().then(() => {
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const { account } = payload;
      msalInstance.setActiveAccount(account);
    }
    if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
      msalInstance.acquireTokenRedirect({
        ...loginRequest,
        prompt: 'login',
      });
    }
  });

  const root = createRoot(document.getElementById('root') as HTMLElement);

  return root.render(
    <BrowserRouter>
      <App instance={msalInstance} />
    </BrowserRouter>
  );

});



