import { AtlasStatus } from "types/AtlasStatus";
import { createMongoId } from "utils/createMongoId";
import { IIssue, IssueType } from "./issue.model";
import { IStation } from "./station.model";

export function initIssue(
  issue: Partial<IIssue> & {
    createdBy: IIssue["createdBy"];
    parent: IIssue["parent"];
    station: IStation["_id"];
  }
): IIssue {
  return {
    _id: createMongoId(),
    description: "",
    createdDate: new Date().toISOString(),
    issueType: IssueType.Station,
    issueNumber: 1,
    severity: AtlasStatus.YELLOW,
    category: "",
    subCategory: "",
    resolution: "",
    resolvedBy: null,
    resolvedDate: "",
    timeLost: null,
    ...issue,
  };
}

export function issueShouldShowInStation(issue: IIssue, station: IStation) {
  return (issue.station && station.projectIssueFilter.includes(issue.station)) || !station.projectIssueFilter.length
}

