import { oneMinute } from "constants/intervals";
import { getResponseBody } from "store/api/utils/apiUtilv2";
import { ISite } from "store/models/site.model";
import { atlasApi } from "store/services/api";


export const sitesApi = atlasApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getSites: builder.query<ISite[], void>({
        query: () => "sites",
        transformResponse: getResponseBody,
        providesTags: ["Sites"],
        keepUnusedDataFor: oneMinute * 60 * 8
    }),


  })
});

export const { useGetSitesQuery } = sitesApi;
