import { ITaktSheetTask, ITask, TaskType } from "./task.model";
import { createMongoId } from "utils/createMongoId";

export function initTaktSheetTask (task?: Partial<ITaktSheetTask>): ITaktSheetTask{
  return {
    _id: createMongoId(),
    description: "",
    timeRequired: 0,
    personnelType: "",
    completed: false,
    completedBy: null,
    createdBy: "",
    removedBy: null,
    type: TaskType[0],
    customInputs: [],
    ...task
  }
}

export function initTask (task?: Partial<ITask>): ITask {
  return {
    _id: createMongoId(),
    description: "",
    timeRequired: 0,
    personnelType: "",
    stations: [],
    type: TaskType[0],
    customInputs: [],
    ...task
  }
}

export function status(task: ITaktSheetTask){
  return  task.completed ? "Complete" : "Incomplete";
}

export function isSubContracted(task: ITask | ITaktSheetTask){
  return task.personnelType === "Contractor"
}


