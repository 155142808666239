import React from "react";
import {styled, Box} from "@mui/material"
import { CircularProgress } from "@mui/material";

const Root = styled(Box)`
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100vh;
`;

function Loader() {
  return (
    <Root>
      <CircularProgress color="primary" />
    </Root>
  );
}

export default Loader;
