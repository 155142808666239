import {initializeApp} from "firebase/app";
import {getStorage, ref, StorageReference, listAll} from "firebase/storage"
import "firebase/storage";
import "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "atlas-global.firebaseapp.com",
  projectId: "atlas-global",
  storageBucket: "atlas-global.appspot.com",
  messagingSenderId: "502796490414",
  appId: "1:502796490414:web:6d3411438070fab6a1bf3f",
  measurementId: "G-TPD39RNNCZ",
};

export const app = initializeApp(firebaseConfig);

const storage = getStorage(app);

const envFolder = process.env.REACT_APP_ATLAS_ENV;

export { envFolder, storage };

export const storageRef = ref(storage);

export async function getFirebaseFiles(
  bucket: string,
  parentId: string,
  callback?: (files: StorageReference[]) => void
) {
  const envFolder = process.env.REACT_APP_ATLAS_ENV;
  const fileRef = ref(storage,`${envFolder}/${bucket}/${parentId}`)
  const allFiles = await listAll(fileRef)

  if (callback) {
    callback(allFiles.items);
    return allFiles.items;
  } else {
    return allFiles.items;
  }
}
