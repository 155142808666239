export const createMongoId = function () {
    // eslint-disable-next-line no-bitwise
    var timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
    return (
      timestamp +
      "xxxxxxxxxxxxxxxx"
        .replace(/[x]/g, function () {
          // eslint-disable-next-line no-bitwise
          return ((Math.random() * 16) | 0).toString(16);
        })
        .toLowerCase()
    );
  };
  