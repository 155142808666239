import store from "store/store";
import { ILink } from "./link.model";
import { createMongoId } from "utils/createMongoId";

export function initLink(link?: Partial<ILink>): ILink {
  return {
    _id: createMongoId(),
    name: "",
    uploadedBy: store.getState().auth.loggedInUser._id,
    link: "",
    dateUploaded: new Date().toISOString(),
    ...link
  }
}