import merge from "deepmerge";
import { blue, green, grey, red, yellow } from "@mui/material/colors";
import Themes from "types/Themes";

const customBlue = {
  50: "#e9f0fb",
  100: "#c8daf4",
  200: "#a3c1ed",
  300: "#7ea8e5",
  400: "#6395e0",
  500: "#4782da",
  600: "#407ad6",
  700: "#376fd0",
  800: "#2f65cb",
  900: "#2052c2 ",
};

const clear = "transparent"

const defaultVariant: VariantType = {
  name: Themes.light,
  palette: {
    mode: "light",
    primary: {
      main: customBlue[700],
      contrastText: "#FFF",
    },
    secondary: {
      main: customBlue[500],
      contrastText: "#FFF",
    },
    background: {
      default: "#F7F9FC",
      paper: "#FFF",
    },
    statusColors: {
      "red": {
        color: "white",
        background: red[700]
      },
      yellow: {
        color: "black",
        background: yellow[700]
      },
      green: {
        color: "white",
        background: green[700]
      },
      blue: {
        color: "white",
        background: blue[700]
      },
      clear: {
        color: "gray",
        background: clear
      }
    }
  },
  header: {
    color: grey[300],
    background: blue[900],
    search: {
      color: grey[800],
    },
    indicator: {
      background: customBlue[600],
    },
  },
  footer: {
    color: grey[500],
    background: "#FFF",
  },
  sidebar: {
    color: grey[200],
    background: isStaging() ?  blue[900] : "#233044",
    header: {
      color: grey[200],
      background: "#233044",
      brand: {
        color: customBlue[500],
      },
    },
    footer: {
      color: grey[200],
      background: isStaging() ?  blue[900] : "#1E2A38",
      online: {
        background: green[500],
      },
    },
    badge: {
      color: "#FFF",
      background: customBlue[500],
    },
  },
};

const darkVariant = merge(defaultVariant, {
  name: Themes.dark,
  palette: {
    mode: "dark",
    primary: {
      main: customBlue[600],
      contrastText: "#FFF",
    },
    background: {
      default: "#1B2635",
      paper: "#233044",
    },
    text: {
      primary: "rgba(255, 255, 255, 0.95)",
      secondary: "rgba(255, 255, 255, 0.5)",
    },
    divider: "rgb(81, 81, 81)",
    statusColors: {
      red: {
        color: "white",
        background: red[800]
      },
      yellow: {
        color: "black",
        background: yellow[800]
      },
      green: {
        color: "white",
        background: green[800]
      },
      blue: {
        color: "white",
        background: blue[800]
      },
    }
  },
  header: {
    color: grey[300],
    background: blue[900],
    search: {
      color: grey[200],
    },
  },
  footer: {
    color: grey[300],
    background: isStaging() ?  blue[900] : "#233044",
  },
});

const variants: Array<VariantType> = [
  defaultVariant,
  darkVariant,
];

export default variants;

export type VariantType = {
  name: string;
  palette: {
    primary: MainContrastTextType;
    secondary: MainContrastTextType;
    mode: "light" | "dark";
    background: PaperBgType;
    divider?: string;
    statusColors: StatusColors;
  };
  header: ColorBgType & {
    search: {
      color: string;
    };
    indicator: {
      background: string;
    };
  };
  footer: ColorBgType;
  sidebar: ColorBgType & {
    header: ColorBgType & {
      brand: {
        color: string;
      };
    };
    footer: ColorBgType & {
      online: {
        background: string;
      };
    };
    badge: ColorBgType;
  };
};

export type MainContrastTextType = {
  main: string;
  contrastText: string;
};
export type MuiColorContrastType = {
  main: string;
  dark: string;
  darker: string;
  light: string;
  lighter: string;
};
export type ColorBgType = {
  color: string;
  background: string;
};
export type PaperBgType = {
  default: string;
  paper: string;
}

export type StatusColors = Record<"red" | "yellow" | "green" | "blue" | "clear", ColorBgType>

export function isStaging(){
  return process.env.REACT_APP_ATLAS_ENV === "staging";
}