import { IPurchaseRequisition } from "store/models/purchase-requistion.model";
import ApiEvents from "types/ApiEvents";
import { makeAPICallv2 } from "./utils/apiUtilv2";
import { HTTPMethod } from "./utils/HttpMethodsEnum";

export const createPurchaseRequisition = (requestedMaterials: IPurchaseRequisition, apiEvents?: ApiEvents) => {
    makeAPICallv2({
        route: "zippy/epreq",
        method: HTTPMethod.POST,
        body: requestedMaterials,
        ...apiEvents,
    })
}