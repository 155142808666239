import { oneMinute } from "constants/intervals";
import { getResponseBody } from "store/api/utils/apiUtilv2";
import { ILookup } from "store/models/lookup";
import { atlasApi } from "store/services/api";
import { HttpSuccessResponse } from "types/HttpResponse";

export const lookupApi = atlasApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getLookupByName: builder.query<ILookup, string>({
      query: (lookupName) => `lookup/${lookupName}`,
      transformResponse: (response: HttpSuccessResponse) => response.body.options,
      providesTags: (result, error, name) => [{ type: "Lookups", id: name }],
      keepUnusedDataFor: oneMinute * 60 * 8
    }),
    updateLookup: builder.mutation<ILookup, Pick<ILookup, "name" | "options">>({
        query: (update) => ({
          url: `/lookup`,
          method: "PUT",
          body: update,
        }),
        transformResponse: getResponseBody,
        invalidatesTags: (result, error, args) => [
          { type: "Lookups", id: args?.name },
        ],
      }),
  }),
});

export const { useGetLookupByNameQuery, useUpdateLookupMutation } = lookupApi;
