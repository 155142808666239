import { IChatMessage } from "./message.model";
import { createMongoId } from "utils/createMongoId";

export function initMessage(
  message: Partial<IChatMessage> & { user: IChatMessage["user"] }
): IChatMessage {
  return {
    _id: createMongoId(),
    date: new Date().toISOString(),
    message: "",
    ...message,
  };
}
