import _ from "lodash";

export type IssueCategoriesByIssueType = {
  [issueType: string]: { [category: string]: { [subCategory: string]: string[] } };
};

export enum LOOKUPS {
  ISSUE_CATEGORIES_BY_ISSUE_TYPE = "issueCategoriesByIssueType",
  BUILDING_SUB_SHOPS = "buildingSubShops",
  EMPLOYEE_UNASSIGNED_REASONS = "employeeUnassignedReasons",
  UNITS_OF_MEASUREMENT = "unitsOfMeasurement",
  PERSONNEL_TYPES = "personnelTypes",
  PRODUCTION_LINES = "productionLines",
  META_TAGS = "metaTags",
  ACCOUNTING_TYPES = "accountingTypes",
  PLAN_CATEGORIES = "planCategories",
  PLAN_PRESETS = "planPresets",
  CATEGORY_STAKEHOLDERS = "categoryStakeholders"
}

export type Lookups = {
  [LOOKUPS.ISSUE_CATEGORIES_BY_ISSUE_TYPE]: IssueCategoriesByIssueType;
  [LOOKUPS.BUILDING_SUB_SHOPS]: { [building: string]: { [subShop: string]: string[] } };
  [LOOKUPS.EMPLOYEE_UNASSIGNED_REASONS]: string[];
  [LOOKUPS.UNITS_OF_MEASUREMENT]: string[];
  [LOOKUPS.PERSONNEL_TYPES]: string[];
  [LOOKUPS.PRODUCTION_LINES]: string[];
  [LOOKUPS.META_TAGS]: { [category: string]: string[] };
  [LOOKUPS.ACCOUNTING_TYPES]: string[];
  [LOOKUPS.PLAN_CATEGORIES]: string[];
  [LOOKUPS.PLAN_PRESETS]: [{ [preset: string]: string[] }];
  [LOOKUPS.CATEGORY_STAKEHOLDERS]: { [category: string]: string[] }[];
};

export const getUniqueIssueCategories = (issueCategoriesByIssueType: IssueCategoriesByIssueType): string[] => {
  return _.uniq(Object.keys(issueCategoriesByIssueType).reduce(
    (issueCategories, issueType) => {
      return [
        ...issueCategories,
        ...Object.keys(issueCategoriesByIssueType[issueType]),
      ];
    },
    [] as string[]
  ));
};
