import Themes from "types/Themes";
import { createMongoId } from "utils/createMongoId";
import { IUser } from "./user.model";

export function initUser(user?: Partial<IUser>): IUser {
  return {
    _id: createMongoId(),
    lastName: "",
    firstName: "",
    email: "",
    lastLogin: "",
    phoneNumber: "",
    defaultTheme: Themes.dark,
    active: true,
    ...user,
  };
}

export function fullName(user: IUser | undefined) {
  return user ? `${user.firstName} ${user.lastName}` : '';
}

export function initials(user: IUser | undefined | null) {
  if (!user) return "";
  const firstIntial = user.firstName?.[0] || "";
  const secondInitial = user.lastName?.[0] || "";
  return firstIntial + secondInitial;
}

export const isSuper = (userRole: string) => {
  return userRole === "SUPER USER";
}

export const isAdministrator = (userRole: string) => {
  return userRole === "ADMINISTRATOR";
}