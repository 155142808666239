import * as employee from "./employee";
import * as issue from "./issue";
import * as label from "./label";
import * as material from "./material";
import * as message from "./message";
import * as orderItem from "./order-item";
import * as plan from "./plan";
import * as project from "./project";
import * as requiredMaterial from "./requiredMaterial";
import * as station from "./station";
import * as user from "./user";
import * as zip from "./zip";
import * as task from "./task";
import * as taktSheet from "./taktSheet";
import * as link from "./link";
import * as bestKnownMethod from "./bestKnownMethod";
import * as qualification from "./qualification";
import * as purchaseRequisition from "./purchaseRequisition";

export default {
  employee,
  issue,
  label,
  material,
  message,
  orderItem,
  plan,
  project,
  requiredMaterial,
  station,
  user,
  zip,
  task,
  taktSheet,
  link,
  bestKnownMethod,
  qualification,
  purchaseRequisition,
};