import React from "react";
import LightIcon from "@mui/icons-material/Brightness7";
import DarkIcon from "@mui/icons-material/Brightness4";
import { IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentTheme, setTheme } from "store/slices/systemSlice";
import Themes from "types/Themes";
import { isLightTheme } from "utils/formatUtils";
import { useUpdateUserMutation } from "store/services/user";
import { useAuthContext } from "auth/use-auth-context";

const ToggleThemeButton = () => {
  const dispatch = useDispatch();
  const [updateUser] = useUpdateUserMutation()
  const {user} = useAuthContext()
  const currentTheme = useSelector(selectCurrentTheme);


  const handleThemeChange = () => {
    if (!user) return;
    dispatch(setTheme(toggleTheme()));
    updateUser({...user, defaultTheme: toggleTheme()})
  };

  function toggleTheme() {
    return isLightTheme(currentTheme) ? Themes.dark : Themes.light;
  }

  return (
    <IconButton color="inherit" onClick={handleThemeChange}>
      {currentTheme === "LIGHT" ? <DarkIcon /> : <LightIcon />}
    </IconButton>
  );
};

export default ToggleThemeButton;
