import { createPurchaseRequisition } from "store/api/purchaseRequistion";
import {
  IPurchaseRequisition,
  IPurchaseRequisitionMaterial,
} from "./purchase-requistion.model";

export function initPurchaseReq(purchaseReq?: Partial<IPurchaseRequisition>): IPurchaseRequisition {
  return {
    materials: [],
    reason: "",
    ...purchaseReq,
  };
}

export function initPurchaseReqMaterial(
  purchaseReqMaterial?: Partial<IPurchaseRequisitionMaterial>
) {
  return {
    description: "",
    unitOfMeasure: "",
    quantity: "",
    needByDate: new Date(),
    projectName: "",
    projectNumber: "",
    materialProjectNumber: "",
    ...purchaseReqMaterial,
  };
}

export const createPurchaseReq = createPurchaseRequisition;