import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppStateType } from "store/store";
import { GlobalAlert } from "types/GlobalAlert";
import Themes from "types/Themes";

export interface SystemStateType {
  currentTheme: Themes;
  globalAlert: GlobalAlert;
  showGlobalAlert: boolean;
  sideBarOpen: boolean;
}

const initialState: SystemStateType = {
  currentTheme: localStorage.getItem("lastTheme") as Themes || Themes.light,
  showGlobalAlert: false,
  globalAlert: {
    message: "",
  },
  sideBarOpen:  isMediumUp() ?  true: false,
};

function isMediumUp(){
  //960 px is default breakpoint for MUI md
  const medium = 960
  return screen.width >= medium
}

const systemSlice = createSlice({
  name: "system",
  initialState,
  reducers: {
    setTheme(state, action: PayloadAction<SystemStateType["currentTheme"]>) {
      localStorage.setItem('lastTheme', action.payload)
      state.currentTheme = action.payload;
    },
    setGlobalAlert(state, action: PayloadAction<GlobalAlert>) {
      state.globalAlert = { ...state.globalAlert, ...action.payload };
    },
    setSideBarOpen(state, action: PayloadAction<boolean>) {
      state.sideBarOpen = action.payload;
    },
  },
});

export const {
  setTheme,
  setSideBarOpen,
  setGlobalAlert,
} = systemSlice.actions;


export default systemSlice.reducer;

export const selectSideBarOpen = (state: AppStateType) =>
  state.system.sideBarOpen;

export const selectCurrentTheme = (state: AppStateType) =>
  state.system.currentTheme;

export const selectAlertMessage = (state: AppStateType) =>
  state.system.globalAlert;

