import { ITaktSheet } from "store/models/taktSheet.model";
import { isSubContracted } from "store/models/task";
import { createMongoId } from "utils/createMongoId";
import { IPlan } from "./plan.model";

export function initPlan(plan: Partial<IPlan> & {parentPlan: IPlan["parentPlan"]}): IPlan {
  return {
    _id: createMongoId(),
    name: "",
    type: "template",
    taktSheets: [],
    materialsToOrder: [],
    startingArea: '',
    links:[],
    isArchived: false,
    createdDate: new Date().toISOString(),
    metaTags: [],
    ...plan
  }
}


export const isExecuted = (plan: IPlan) => plan?.type === "execution";

export const getPlanHours = (
  plan: IPlan,
  taktSheets: { [taktSheetId: string]: ITaktSheet }
) => {
  const currentPlanTaktSheets =
    plan.taktSheets.map((taktSheetId) => taktSheets[taktSheetId]) || [];
  const currentPlanTasks = currentPlanTaktSheets.flatMap(
    (taktSheet) => taktSheet ? taktSheet.tasks : []
  );
  const planTimeRequiredTotal = currentPlanTasks.reduce((sum, task) => {
    return taskIsValid() ? sum + (task.timeRequired as number) : sum;
    function taskIsValid() {
      return (
        !task.removedBy && !isSubContracted(task) && Boolean(task.timeRequired)
      );
    }
  }, 0);

  return planTimeRequiredTotal;
}
