import { oneMinute } from "constants/intervals";
import { getResponseBody } from "store/api/utils/apiUtilv2";
import { IArea } from "store/models/area.model";
import { atlasApi } from "store/services/api";

export const areasApi = atlasApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getAreas: builder.query<IArea[], void>({
        query: () => "areas",
        transformResponse: getResponseBody,
        providesTags: ["Areas"],
        keepUnusedDataFor: oneMinute * 60 * 8
    }),


  })
});

export const { useGetAreasQuery } = areasApi;


