import React, { FC } from "react";
import { ClickAwayListenerProps, Snackbar, Alert } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectAlertMessage, setGlobalAlert } from "store/slices/systemSlice";

interface Props {

}

const ErrorMessages: FC<Props> = () => {
  const globalAlert = useSelector(selectAlertMessage);
  const dispatch = useDispatch();

  const clearAlertMessage = () => {
    dispatch(setGlobalAlert({ show: false }));
  };

  const clickAwayListenerProps:
    | Partial<ClickAwayListenerProps>
    | undefined = {
      onClickAway: () => clearAlertMessage(),
      mouseEvent: "onMouseDown",
      touchEvent: "onTouchStart",

    };
  if (globalAlert.disableClickAwayListener) clickAwayListenerProps.onClickAway = () => {};

  return (
    <Snackbar
      open={globalAlert.show}
      autoHideDuration={globalAlert.duration || 3000}
      onClose={clearAlertMessage}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      message={globalAlert.message}
      ClickAwayListenerProps={clickAwayListenerProps}
    >
      <Alert onClose={clearAlertMessage} severity={globalAlert.severity}>
        {globalAlert.message}
      </Alert>
    </Snackbar>
  );
};

export default ErrorMessages;
