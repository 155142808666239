export interface ITask {
  _id: string;
  description: string;
  timeRequired: number,
  personnelType: string,
  stations: string[],
  customInputs: CustomInput[],
  type: typeof TaskType[number]
}

export interface CustomInput {
  _id: string,
  fieldName: string,
  value: string
}

export interface ITaktSheetTask {
  _id: string;
  description: string;
  timeRequired?: number,
  personnelType?: string,
  completed: boolean,
  completedBy: string | null,
  createdBy: string,
  removedBy: string | null,
  customInputs: CustomInput[],
  type: typeof TaskType[number]
}

export type Narrowable =
  | string
  | number
  | boolean
  | symbol
  | object
  | {}
  | void
  | null
  | undefined;
  
export const typedTuple = <T extends Narrowable[]>(...args: T) => args;

export const TaskType = typedTuple("Production", "Safety", "Quality");