import React from "react";
import {styled} from "@mui/material"
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import AuthLayout from "layouts/Auth";
import { Box, Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { selectLoginFailMessage } from "store/slices/authSlice";

const Wrapper = styled(Box)`
  padding: ${(props) => props.theme.spacing(6)};
  text-align: center;
  background: transparent;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function Page500({error, resetErrorBoundary}: {error?: Error, resetErrorBoundary?: (...args: Array<unknown>) => void}) {
  const loginFailMessage = useSelector(selectLoginFailMessage)
  const navigate = useNavigate()
  function handleErrorClick() {
    resetErrorBoundary && resetErrorBoundary()
    navigate("/")
  }
  return (
    <AuthLayout>
    <Wrapper>
      <Helmet title="500 Error" />
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        500
      </Typography>
      <Typography component="h2" variant="h5" align="center" gutterBottom>
        {loginFailMessage?.title || "Internal server error."}
      </Typography>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        {loginFailMessage?.subtitle ? loginFailMessage.subtitle : error?.message ? error.message : "The server encountered something unexpected that didn't allow it to complete the request."}
      </Typography>

      <Button
        variant="contained"
        color="secondary"
        sx={{marginTop: 2}}
        onClick={handleErrorClick}
      >
        Return to website
      </Button>
    </Wrapper>
    </AuthLayout>
  );
}

export default Page500;
