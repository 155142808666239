import { combineReducers } from "redux";
import systemReducer from "store/slices/systemSlice";
import authReducer from "store/slices/authSlice";
import issueReducer from "store/slices/issueSlice";
import stationsReducer from "store/slices/stationSlice";
import { atlasApi } from "store/services/api";

const rootReducer = combineReducers({
  system: systemReducer,
  auth: authReducer,
  issues: issueReducer,
  stations: stationsReducer,
  [atlasApi.reducerPath]: atlasApi.reducer
});

export default rootReducer;
