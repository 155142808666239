import React from "react"
import { createTheme, ThemeProvider as MUIThemeProvider, CssBaseline} from "@mui/material"
import variants, { ColorBgType, MainContrastTextType, MuiColorContrastType, PaperBgType, StatusColors } from "./variants";
import typography from "./typography";
import breakpoints from "./breakpoints";
import { useSelector } from "react-redux";
import { selectCurrentTheme } from "store/slices/systemSlice";
import { ReactNode } from "react";
import Themes from "types/Themes";
import type {} from '@mui/lab/themeAugmentation';

declare module '@mui/material' {
  interface Theme  {
    name: Themes
    palette: {
      primary: MainContrastTextType;
      secondary: MainContrastTextType;
      error: MuiColorContrastType;
      mode: "light" | "dark";
      background: PaperBgType;
      divider: string;
      statusColors: StatusColors;
      text: {
        primary: string;
        secondary: string;
      },
      common: {
        white: string
      },
      action: {
        hover: string
      }
    };
    header: ColorBgType & {
      search: {
        color: string;
      };
      indicator: {
        background: string;
      };
    };
    footer: ColorBgType;
    sidebar: ColorBgType & {
      header: ColorBgType & {
        brand: {
          color: string;
        };
      };
      footer: ColorBgType & {
        online: {
          background: string;
        };
      };
      badge: ColorBgType;
    };
  }
}

type Props = {
  children: ReactNode;
};

export default function ThemeProvider({children}: Props) {

  const currentTheme = useSelector(selectCurrentTheme)

  const createMuiTheme = (name: string) => {
    let themeConfig = variants.find((variant) => variant.name === name);
  
    if (!themeConfig) {
      themeConfig = variants[0];
    }
  
    const borderColor =
      name === "DARK" ? "rgb(81, 81, 81)" : "rgba(0, 0, 0, 0.12)";
  
    return createTheme(
      {
        spacing: 4,
        breakpoints: breakpoints,
        typography: typography,
        // shadows: shadows,
        palette: themeConfig.palette,
        components: {
          MuiPaper: {
            defaultProps: {
              style: {
                borderRadius: "6px",
                backgroundImage: "none"
              }
            }
          },
          MuiCssBaseline: {
            styleOverrides: {
              '*::-webkit-scrollbar': {
                width: '12px',
              },
              '*::-webkit-scrollbar-thumb': {
                backgroundColor: "rgba(33,150,243)",
                borderRadius: "20px",
                border: "4px solid transparent",
                backgroundClip: "content-box",
                cursor: 'pointer'
              },
              '*::-webkit-scrollbar-thumb:hover': { 
                backgroundColor: `rgba(30,136,229,0.95)`,
                width: '18px'
              },
              input: {
                '&[type=number]': {
                  'MozAppearance': 'textfield',
                },
                '&::-webkit-outer-spin-button': {
                  'WebkitAppearance': 'none',
                  margin: 0,
                },
                '&::-webkit-inner-spin-button': {
                  'WebkitAppearance': 'none',
                  margin: 0,
                },
              },
              // Perfect scrollbar
              ".ps__thumb-y:hover, .ps__thumb-x:hover": {
                width: "12px",
                cursor: "pointer"
              },
              "@keyframes fadeIn": {
                  "0%": {
                      opacity: 0
                  },
                  "100%": {
                      opacity: 1
                  }
              },
              '.react-tel-input': {
                backgroundColor: `${themeConfig.palette.background.paper} !important`,
                '.special-label' : {
                  backgroundColor: `${themeConfig.palette.background.paper} !important`,
                }
              },
            }
          }
        }
      },
      {
        name: themeConfig.name,
        header: themeConfig.header,
        footer: themeConfig.footer,
        sidebar: themeConfig.sidebar,
        border: borderColor,
      }
    );
  };

  const theme = createMuiTheme(currentTheme)
  
  return (
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
  );
  

}
