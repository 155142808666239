import { createMongoId } from "utils/createMongoId";
import { IMaterial } from "./material.model";

export function initMaterial(material: Partial<IMaterial>): IMaterial {
  return {
    _id: createMongoId(),
    itemNumber: "",
    description: "",
    supplier: "",
    unitOfMeasure: "",
    unitCost: 0,
    primaryCategory: "",
    subCategory: "",
    subInventory: null,
    locator: null,
    min: null,
    max: null,
    accountingType: "",
    supplierItemIdentifier: null,
    kittingId: null,
    purchaseOrder: "",
    poLineNumber: "",
    quantityOnHand: 0,
    ...material,
  };
}

