import { oneMinute } from "constants/intervals";
import { getResponseBody } from "store/api/utils/apiUtilv2";
import { IUser } from "store/models/user.model";
import { atlasApi } from "store/services/api";
import { HttpResponseWithFirebaseToken } from "types/HttpResponse";

export const userApi = atlasApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    login: builder.mutation<HttpResponseWithFirebaseToken, void>({
      query: () => ({ method: "POST", url: "users/login" }),
      // No transform response here because the authSlice is authenticating the user with firebase in an 'ExtraReducer' and needs access to the raw response
      extraOptions: { maxRetries: 0 },
    }),
    updateUser: builder.mutation<IUser, IUser>({
      query: (body) => ({ method: "PATCH", url: "users", body }),
      transformResponse: getResponseBody,
      extraOptions: { maxRetries: 0 },
      invalidatesTags: (result, err, {_id}) => [{type: "User", id: "LIST"}, {type: "User", id: _id}],
    }),
    getUsers: builder.query<(IUser & {userRole: string})[], void>({
      query: () => 'users',
      transformResponse: getResponseBody,
      providesTags: [{type: "User", id: "LIST"}],
      keepUnusedDataFor: oneMinute * 30
    }),
    getUserById: builder.query<IUser, string>({
      query: (id) => `users/${id}`,
      transformResponse: getResponseBody,
      providesTags: (result, error, id) => [{type: "User", id}],
    }),
    createNewUser: builder.mutation<IUser, Partial<IUser>>({
      query: (user) => ({ method: "POST", url: "users", body: user }),
      transformResponse: getResponseBody,
      invalidatesTags: [{type: "User", id: "LIST"}],
      extraOptions: {maxRetries: 0}
    }),
  }),
});

export const { useLoginMutation, useUpdateUserMutation, useCreateNewUserMutation, useGetUsersQuery } = userApi;
